<template>
  <div>
    <h6>Page Not Found!</h6>
    <div v-if="isLoggedIn">
      Go to <router-link to="/">Home</router-link> to access all available
      options!
    </div>
    <div v-else>
      <router-link to="/login">Log in</router-link> to use the app!
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
};
</script>

<style>
</style>