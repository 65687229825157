<template>
  <div style="max-width: 450px" class="p-3">
    <form
      class="
        row row-cols-auto
        g-3
        align-items-center
        border border-success
        rounded
        border-3
        p-2
        pt-0
      "
      @submit.prevent="submitForm"
    >
      <!-- FORM HEADER -->
      <div class="col-12">
        <h4 class="mb-3 fw-bold card-title">Login Form</h4>
        <h5 class="card-subtitle d-inline">
          Use your
          <img
            src="../assets/simpro-logo-blue.svg"
            alt="SimPRO"
            class="img-fluid d-inline-block mb-1"
            style="max-height: 1.65rem; width: auto"
          />
          credentials
        </h5>
      </div>
      <!-- ALERT MESSAGE -->
      <div
        v-if="message.show"
        class="alert alert-warning fade show col-12"
        role="alert"
      >
        <strong>Error!</strong> {{ message.message }}
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          @click="message.show = false"
        ></button>
      </div>
      <!-- USERNAME -->
      <div class="col-12">
        <label class="visually-hidden" for="inlineFormInputGroupUsername">
          Username
        </label>
        <div class="input-group">
          <div class="input-group-text">
            <i class="bi bi-person-circle"> Username:</i>
          </div>
          <input
            type="text"
            class="form-control"
            id="inlineFormInputGroupUsername"
            placeholder="Username"
            v-model.trim="user.userName"
          />
        </div>
      </div>
      <!-- PASSWORD -->
      <div class="col-12">
        <label class="visually-hidden" for="inlineFormInputGroupPassword">
          Password
        </label>
        <div class="input-group">
          <div class="input-group-text">
            <i class="bi bi-person-circle"> Password:</i>
          </div>
          <input
            type="password"
            class="form-control"
            id="inlineFormInputGroupPassword"
            placeholder="Password"
            v-model.trim="user.password"
          />
        </div>
      </div>
      <!-- REMEMBER ME -->
      <div class="col d-flex justify-content-center">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            id="form1Example3"
            v-model="user.rememberMe"
          />
          <label class="form-check-label" for="form1Example3">
            Remember me
          </label>
        </div>
      </div>
      <!-- SUBMIT BUTTON -->
      <div class="col-12">
        <button type="submit" class="btn btn-success w-100">
          <span
            v-if="isLoading"
            class="spinner-border spinner-border-sm float-start"
            role="status"
            aria-hidden="true"
          ></span>
          <span class="float-end">
            <i
              class="bi bi-box-arrow-in-right"
              :class="{ disabled: isLoading }"
            ></i>
            Log In
          </span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {
        userName: localStorage.userName,
        password: null,
        rememberMe: localStorage.rememberMe,
      },
      message: {
        message: "",
        show: false,
      },
      isLoading: false,
    };
  },
  methods: {
    async submitForm() {
      this.isLoading = true;

      if (this.user.userName == "" || this.user.password == "") {
        this.message.message = "Username & Password are mandatory!";
        this.message.show = true;
        this.isLoading = false;
        return;
      }

      try {
        // trimitem datele catre actiunea de logare din sotre 'auth.js'
        const response = await this.$store.dispatch("loginUser", {
          userName: this.user.userName,
          password: this.user.password,
          rememberMe: this.user.rememberMe,
        });

        if (response === "ok") {
          // redirectionare catre home (/) sau catre link-ul de riderict
          const redirectUrl = "/" + (this.$route.query.redirect || "");
          this.$router.replace(redirectUrl);
        } else {
          this.message.message = response || "Failed to login, try later.";
          this.message.show = true;
        }
      } catch (error) {
        this.message.message =
          error.message || "Failed to authenticate, try later.";
        this.message.show = true;
      }
      this.isLoading = false;
    },
  },
};
</script>

<style>
</style>