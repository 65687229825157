<template>
  <section id="cocPage">
    <!-- HEADER OF PAGE -->
    <div id="forPDF-header" ref="headerPDF" class="row">
      <div class="row m-0 p-0 mb-2 fw-bold d-block">
        <div class="col-auto float-start ms-5">
          <img
            src="../assets/logo.png"
            alt="RSB-portal"
            height="45"
            class="d-inline-block"
            id="logo"
          />
        </div>
        <div class="col-auto float-end">
          <h6 class="float-start fw-bold">
            Electrical Certificate<br />of Compliance (COC)
          </h6>
        </div>
      </div>
    </div>

    <!-- BODY OF PAGE -->
    <div id="forPDF-body">
      <!-- JOB & CUSTOMER DETAILS -->
      <div class="row border border-dark pdfBodyArea">
        <div class="row">
          <div class="col-md-3">
            <h5 class="fw-bold">JOB NUMBER</h5>
            <div class="input-group">
              <input
                type="text"
                id="inputPassword5"
                class="form-control"
                v-model="job.jobNumber"
                @keyup.enter="getTaskDetails"
                :disabled="isFetchingData"
              />
              <button
                data-html2canvas-ignore="true"
                type="button"
                class="btn btn-primary"
                @click="getTaskDetails"
                v-if="!isFetchingData"
              >
                <i class="bi bi-download"></i>
              </button>
              <div class="input-group-text bg-warning" v-if="isFetchingData">
                <div class="spinner-border text-dark" role="status"></div>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <h5 class="fw-bold">Reference/Certificate ID No:</h5>
            <span class="fst-italic">
              This form has been designed to be used by licensed electrical
              workers to certify that installations or Part installations under
              Part 1 or Part 2 of AS/NZS 3000 are safe to be connected to the
              specified system of electrical supply
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- Signature PAD -->
    <signature-pad
      v-if="!isUploadingData"
      :isVisible="signaturePad.isVisible"
      @cancelSignature="cancelSignature"
      @saveSignature="addSignature($event)"
    ></signature-pad>
  </section>
</template>

<script>
import SignaturePad from "../components/UI/SignaturePad.vue";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

console.log(html2canvas, jsPDF);

export default {
  components: { SignaturePad },
  data() {
    return {
      fetchingError: false,
      fetchingErrorMessage: null,
      isFetchingData: false,
      isUploadingData: false,
      uploadMessageShow: false,
      uploadMessageOk: true,
      uploadMessage: null,
      job: {
        jobNumber: null,
      },
      signaturePad: {
        isVisible: false,
        jobSignatureIndex: null,
        signatureData: null,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
#cocPage {
  #forPDF-body {
    .pdfBodyArea {
      padding: 0.7rem;
      background-color: rgba($color: #f2f2f2, $alpha: 0.8);
      margin-bottom: 0.7rem;
    }
  }
}
</style>