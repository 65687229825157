<template>
  <div class="outer-div" v-if="isVisible">
    <div class="container">
      <h5 class="fw-bold text-light">Draw your signature</h5>
      <VueSignaturePad
        id="signature"
        width="100%"
        height="100%"
        ref="signaturePad"
        :options="options"
      />
      <div class="buttons">
        <button type="button" class="btn btn-warning" @click="undo">
          <i class="fas fa-undo"></i> Undo Signature
        </button>
        <button type="button" class="btn btn-success" @click="save">
          <i class="fas fa-file-signature"></i> Sign document
        </button>
        <!-- <button @click="change">Change Color</button> -->
        <!-- <button @click="resume">Resume Color</button> -->
        <button type="button" class="btn btn-secondary" @click="cancel">
          <i class="fas fa-times"></i> Cancel Signature
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["isVisible"],
  data() {
    return {
      signature: null,
      options: {
        penColor: "black",
        dotSize: (2.5 * (0.5 + 2.5)) / 2,
      },
    };
  },
  methods: {
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      this.$emit("saveSignature", { isEmpty, data });
    },
    change() {
      this.options = {
        penColor: "#00f",
      };
    },
    resume() {
      this.options = {
        penColor: "#c0f",
      };
    },
    cancel() {
      this.$emit("cancelSignature");
    },
  },
};
</script>

<style scoped>
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
  margin: 0 auto;
  padding: 0;
  position: relative;
  max-height: 300px;
  max-width: 650px;
  flex: 1 1 0;
  box-sizing: border-box;
  /* max-width: 80%; */
  /* max-height: 50%; */
}

.outer-div {
  position: fixed;
  z-index: 13;
  /* background-color: darksalmon; */
  background-color: rgba(0, 0, 0, 1);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 0;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  padding-top: 25%; */

  /*
     * Setting all four values to 0 makes the element as big
     * as its next relative parent. Usually this is the viewport.
     */
}

.container {
  width: 100%;
  padding: 8px 16px;
  position: relative;
  height: 80%;
  width: 90%;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  margin: 0;
  margin: 0 auto;
  padding-top: 95px;
}

.buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 8px;
}
</style>