<template>
  <section>
    <!-- HEADER OF PAGE -->
    <div id="forPDF-header" ref="headerPDF" class="row">
      <div class="row m-0 p-0 mb-2 fw-bold d-xl-block d-none">
        <div class="col-auto float-start ms-5">
          <img
            src="../assets/logo.png"
            alt="RSB-portal"
            height="45"
            class="d-inline-block"
            id="logo"
          />
        </div>
        <div class="col-auto text-uppercase float-start">
          <h2 class="float-start">Task Analysis</h2>
        </div>
      </div>
      <!-- ALERT MESSAGE -->
      <div class="row m-0 p-0 px-4" ref="alertMessageDiv">
        <div
          v-if="fetchingError"
          class="alert alert-warning fade show col-12"
          role="alert"
        >
          <strong>Error!</strong> {{ fetchingErrorMessage }}
          <button
            type="button"
            class="btn btn-close float-end"
            aria-label="Close"
            @click="fetchingError = false"
          ></button>
        </div>
      </div>
      <!-- UPLOAD PDF MESSAGE -->
      <div class="row m-0 p-0 px-4">
        <div
          v-if="uploadMessageShow"
          class="alert fade show col-12"
          :class="{
            'alert-success': uploadMessageOk,
            'alert-warning': !uploadMessageOk,
          }"
          role="alert"
        >
          <strong>Message!</strong> <span v-html="uploadMessage"></span>
          <button
            type="button"
            class="btn btn-close float-end"
            aria-label="Close"
            @click="uploadMessageShow = false"
          ></button>
        </div>
      </div>
    </div>
    <!-- PAGINA CARE SE SALVEAZA CA SI PDF -->
    <div v-if="isUploadingData" class="spinner-grow" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div v-else id="pdfBodyHTML" ref="pdfBodyHTML">
      <!-- 1st PAGE -->
      <div id="pdf1stPage">
        <div class="row p-0 m-0">
          <!-- JOB NUMBER -->
          <div class="col-xs-12 col-md-6">
            <div class="input-group">
              <span :class="classes.title2cols"> Job Number: </span>
              <input
                type="text"
                class="form-control"
                placeholder="Job Number"
                aria-label="jobNumber"
                v-model="job.jobNumber"
                @keyup.enter="getTaskDetails"
                :disabled="isFetchingData"
              />
              <div class="input-group-text bg-warning" v-if="isFetchingData">
                <div class="spinner-border text-dark" role="status"></div>
              </div>
              <button
                data-html2canvas-ignore="true"
                type="button"
                class="btn btn-primary"
                @click="getTaskDetails"
                v-if="!isFetchingData"
              >
                <i class="bi bi-download"></i>
              </button>
            </div>
          </div>
          <!-- JOB NAME -->
          <div class="col-xs-12 col-md-6">
            <div class="input-group">
              <span :class="classes.title2cols"> Job Name: </span>
              <input
                type="text"
                class="form-control"
                placeholder="Job Name"
                aria-label="jobName"
                disabled
                v-model="job.jobName"
              />
            </div>
          </div>
        </div>
        <div class="row p-0 m-0">
          <!-- JOB ADDRESS -->
          <div class="col-xs-12 col-md-6">
            <div class="input-group">
              <span :class="classes.title2cols"> Job Address: </span>
              <textarea
                class="form-control"
                wrap="hard"
                rows="2"
                max-rows="2"
                cols="20"
                placeholder="Job Address"
                aria-label="jobAddress"
                disabled
                v-model="job.jobAddress"
              ></textarea>
            </div>
          </div>
          <!-- JOB DATE -->
          <div class="col-xs-12 col-md-6">
            <div class="input-group">
              <span :class="classes.title2cols"> Date: </span>
              <input
                type="text"
                class="form-control"
                placeholder="Job Date"
                aria-label="jobDate"
                disabled
                v-model="job.jobDate"
              />
            </div>
          </div>
        </div>
        <div class="row p-0 m-0 mx-2 my-1 px-1">
          <!-- LOCATION / SITE -->
          <div class="col-xs-12 col-md-4 border text-start">
            <span class="fw-bold">LOCATION/SITE:</span><br />
            {{ job.jobLocationSite }}
          </div>
          <!-- JOB DESCRIPTION -->
          <div class="col-xs-12 col-md-4 border text-start">
            <span class="fw-bold">JOB DESCRIPTION:</span><br />
            {{ job.jobDescription }}
          </div>
          <!-- CUSTOMER -->
          <div class="col-xs-12 col-md-4 border text-start">
            <span class="fw-bold">CUSTOMER:</span><br />
            {{ job.jobEmployer }}
          </div>
        </div>

        <!-- PPE REQUIRED -->
        <div class="row m-2 border border-2 border-success" v-if="!!job.jobId">
          <div class="fw-bold m-0 my-1 mt-0 p-2 bg-success text-light">
            PPE REQUIRED
          </div>
          <div class="row m-0 p-1">
            <div class="col" v-for="ppe in job.jobPPERequired" :key="ppe.name">
              <div class="input-group">
                <div class="input-group-text bg-light">
                  <input type="checkbox" class="form-check-input" />
                  <img
                    :src="getImagePPE(ppe.picture)"
                    class="rounded mx-2"
                    alt="..."
                    style="max-height: 40px"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- PPE OTHER -->
          <div class="row m-0 mb-2 p-0" v-if="job.jobPPERequiredOther === true">
            <div class="input-group">
              <span class="input-group-text">Other PPE:</span>
              <input
                type="text"
                class="form-control"
                v-model="job.jobPPERequiredOtherText"
              />
            </div>
          </div>
        </div>
        <!-- PLANT REQUIRED -->
        <div class="row m-2 border border-2 border-success" v-if="!!job.jobId">
          <div class="fw-bold m-0 my-1 mt-0 p-2 bg-success text-light">
            PLANT REQUIRED
          </div>
          <div class="row m-0 p-1">
            <div
              class="col"
              v-for="plant in job.jobPlantRequired"
              :key="plant.name"
            >
              <div class="input-group">
                <div class="input-group-text bg-light">
                  <input type="checkbox" class="form-check-input" />
                  <img
                    :src="getImagePPE(plant.picture)"
                    class="rounded mx-2"
                    alt="..."
                    style="max-height: 40px"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- PLANT OTHER -->
          <div
            class="row m-0 mb-2 p-0"
            v-if="job.jobPlantRequiredOther === true"
          >
            <div class="input-group">
              <span class="input-group-text">Other PLANT:</span>
              <input
                type="text"
                class="form-control"
                v-model="job.jobPlantRequiredOtherText"
              />
            </div>
          </div>
        </div>
        <!-- SIGNAGE REQUIRED -->
        <div class="row m-2 border border-2 border-success" v-if="!!job.jobId">
          <div class="fw-bold m-0 my-1 mt-0 p-2 bg-success text-light">
            SIGNAGE REQUIRED
          </div>
          <div class="row m-0 p-1">
            <div
              class="col"
              v-for="signage in job.jobSignageRequired"
              :key="signage.name"
            >
              <div class="input-group">
                <div class="input-group-text bg-light">
                  <input type="checkbox" class="form-check-input" />
                  <img
                    :src="getImagePPE(signage.picture)"
                    class="rounded mx-2"
                    alt="..."
                    style="max-height: 40px"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- PLANT OTHER -->
          <div
            class="row m-0 mb-2 p-0"
            v-if="job.jobSignageRequiredOther === true"
          >
            <div class="input-group">
              <span class="input-group-text">Other SIGNAGE:</span>
              <input
                type="text"
                class="form-control"
                v-model="job.jobSignageRequiredOtherText"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="break-before"></div> -->
      <!-- STEPS, HAZARDS, CONTROLS -->
      <div
        class="row m-2 mt-5 border border-2 border-success"
        v-if="!!job.jobId"
      >
        <!-- LISTA STEPS, HAZARDS, CONTROLS -->
        <div
          class="fw-bold m-0 my-1 mt-0 p-2 bg-success text-light break-avoid"
        >
          Steps, Risks & Controls
        </div>
        <!-- ADAUGA STEP -->
        <div
          class="input-group my-2"
          id="divStepsAdd"
          ref="divStepsAdd"
          data-html2canvas-ignore="true"
        >
          <select
            class="form-select"
            aria-label="Default select example"
            v-model="stepSelectedToAdd"
          >
            <option value="0">
              Select a step and add it at the end of bellow list
            </option>
            <option
              v-for="step in availableSteps"
              :key="step.name"
              :value="step.name"
            >
              {{ step.name }}
            </option>
          </select>
          <button
            class="btn btn-primary"
            type="button"
            v-if="stepSelectedToAdd != 0"
            @click="addStep(stepSelectedToAdd)"
          >
            <i class="bi bi-plus-circle"></i>
          </button>
        </div>
        <!-- RAND STEPS -->
        <div class="row m-0 p-2">
          <div
            v-for="(step, index) in job.jobSteps"
            :key="step.name"
            class="row m-0 p-0 mb-2"
          >
            <button
              type="button"
              class="btn btn-block btn-primary break-avoid"
              @click="step.isExpanded = !step.isExpanded"
            >
              <span class="float-start">
                Step #{{ index + 1 }}: <b>{{ step.name }}</b>
              </span>
              <span class="float-end">
                <i v-if="!step.isExpanded" class="fas fa-chevron-down"></i>
                <i v-else class="fas fa-chevron-up"></i>
              </span>
            </button>
            <!-- RNAD RISKURI -->
            <div v-if="step.isExpanded" class="row m-0 p-0 ps-2 break-avoid">
              <div
                v-for="(risk, index) in step.risks"
                :key="risk.name"
                class="row ms-2 mt-1 break-avoid"
              >
                <button
                  type="button"
                  class="btn btn-block btn-warning break-avoid"
                  @click="risk.isExpanded = !risk.isExpanded"
                >
                  <span class="float-start">
                    Risk #{{ index + 1 }}: <b>{{ risk.name }}</b>
                  </span>
                  <span class="float-end">
                    <i v-if="!risk.isExpanded" class="fas fa-chevron-down"></i>
                    <i v-else class="fas fa-chevron-up"></i>
                  </span>
                </button>
                <!-- RAND CONTROLS -->
                <div v-if="risk.isExpanded" class="row m-0 p-0 ps-2">
                  <div
                    v-for="(control, index) in risk.controls"
                    :key="control.name"
                    class="row ms-2 mt-1"
                  >
                    <button
                      type="button"
                      class="
                        btn btn-block btn-disabled btn-light
                        border-bottom
                        break-avoid
                      "
                    >
                      <span class="col-auto float-start">
                        Control #{{ index + 1 }}:
                      </span>
                      <span class="col-auto float-end">
                        <b>{{ control.name }}</b>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- STEPS OTHER -->
        <div class="row m-0 mb-2 p-0 break-avoid">
          <div class="input-group">
            <div class="input-group-text bg-light">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="job.jobStepsOther"
              />
              <span class="input-group-text bg-light border-0 ms-1">
                Other Mentions
              </span>
            </div>
            <input
              v-if="job.jobStepsOther"
              type="text"
              class="form-control"
              v-model="job.jobStepsOtherText"
            />
          </div>
        </div>
      </div>

      <!-- <div class="break-before"></div> -->
      <!-- ATTENDEES SIGNATURES -->
      <div
        class="row m-2 border border-2 border-success break-avoid mt-2"
        v-if="!!job.jobId"
      >
        <div
          class="fw-bold m-0 my-1 mt-0 p-2 bg-success text-light break-avoid"
        >
          ATTENDEE'S SIGNATURE
        </div>
        <table
          class="
            table table-striped table-hover table-bordered table-sm table-info
            caption-top
            mb-0
            break-avoid
          "
        >
          <caption class="fw-bold p-2 break-avoid">
            <!-- RAND PENTRU ADAUGAREA UNUI EMPLOYEE NOU -->
            <div class="row" data-html2canvas-ignore="true">
              <!-- LISTA EMPLOYEE -->
              <div class="col-sm-12 col-md-6 mb-1">
                <div class="input-group">
                  <label for="" class="input-group-text">Employee: </label>
                  <select
                    name=""
                    id=""
                    class="form-select"
                    v-model="employeeSelectedToAdd"
                    @change="setPositionForEmployee(employeeSelectedToAdd)"
                  >
                    <option value="0">
                      Select an employee to add at the end of bellow list
                    </option>
                    <option value="other">
                      Other attendee (not RSB employee)
                    </option>
                    <option
                      v-for="employee in availableEmployees"
                      :key="employee.Name"
                    >
                      {{ employee.Name }}
                    </option>
                  </select>
                </div>
              </div>
              <!-- LISTA POSITION -->
              <div class="col-sm-12 col-md-6 mb-1">
                <div class="input-group" v-if="employeeSelectedToAdd != 0">
                  <label
                    v-if="employeeSelectedToAdd != 'other'"
                    for=""
                    class="input-group-text"
                  >
                    Position:
                  </label>
                  <label v-else class="input-group-text">
                    Attendee's Name:</label
                  >
                  <select
                    name=""
                    id=""
                    class="form-select"
                    v-model="positionSelectedToAdd"
                    v-if="employeeSelectedToAdd != 'other'"
                  >
                    <option value="0">
                      Select a position for chosen employee
                    </option>
                    <option
                      v-for="position in positionsList"
                      :key="position.Position"
                    >
                      {{ position.Position }}
                    </option>
                  </select>
                  <input
                    v-else
                    type="text"
                    class="form-control"
                    v-model="otherEmployeeName"
                  />
                  <button
                    class="btn btn-primary"
                    type="button"
                    v-if="positionSelectedToAdd != 0"
                    @click="
                      addPosition(employeeSelectedToAdd, positionSelectedToAdd)
                    "
                  >
                    <i class="bi bi-plus-circle"></i>
                  </button>
                </div>
              </div>
            </div>
          </caption>
          <!-- HEADER TABEL EMPLOYEES -->
          <thead class="table-dark break-avoid">
            <tr class="break-avoid">
              <th>#</th>
              <th>Name</th>
              <th>Position</th>
              <th>Signature</th>
              <th>Date</th>
            </tr>
          </thead>
          <!-- BODY/LISTA CU EMPLOYEES ADAUGATI -->
          <tbody>
            <tr
              v-for="(employee, index) in attendeesList"
              :key="employee.Name"
              class="break-avoid"
            >
              <td class="break-avoid">{{ index + 1 }}</td>
              <td>
                {{ employee.Name }}
                <button
                  type="button"
                  class="btn btn-danger"
                  @click="removeEmployee(index + 1)"
                  data-html2canvas-ignore="true"
                >
                  <i class="fas fa-user-slash"></i>
                </button>
              </td>
              <td>{{ employee.Type }}</td>
              <td>
                <div class="row m-0 p-0">
                  <img
                    v-if="job.jobSignatureRequired[index + 1]"
                    :src="job.jobSignatureRequired[index + 1]"
                    class="col-auto rounded px-1 mx-1 bg-light"
                    alt="Signature missing"
                    style="max-height: 50px; width: auto"
                  />
                  <div class="col">
                    <button
                      v-if="!job.jobSignatureRequired[index + 1]"
                      class="btn btn-primary"
                      type="button"
                      @click="getSignature(index + 1)"
                      data-html2canvas-ignore="true"
                    >
                      <i class="fas fa-plus"></i>
                      &nbsp;
                      <i class="fas fa-signature"></i>
                    </button>
                    <button
                      v-if="job.jobSignatureRequired[index + 1]"
                      class="btn btn-danger"
                      type="button"
                      @click="resetSignature(index + 1)"
                      data-html2canvas-ignore="true"
                    >
                      <i class="far fa-trash-alt"></i>
                      &nbsp;
                      <i class="fas fa-signature"></i>
                    </button>
                  </div>
                </div>
              </td>
              <td>{{ job.jobSignatureDate[index + 1] }}</td>
            </tr>
          </tbody>
        </table>
        <!-- zona pentru cel care creaza documentul -->
        <div class="row m-0 p-0 mt-2 p-3 bg-light fw-bold">
          <div class="col-9 float-start">
            <div class="row">Created By</div>
            <div class="row">
              <div class="col-auto">{{ createdBy.Name }}</div>
            </div>
            <div class="row">
              <img
                v-if="job.jobSignatureRequired[0]"
                :src="job.jobSignatureRequired[0]"
                class="rounded px-1 mx-1 bg-light"
                alt="Signature missing"
                style="max-height: 50px; width: auto"
              />
            </div>
            <div class="row">
              <div class="col-auto">
                <button
                  v-if="!job.jobSignatureRequired[0]"
                  class="btn btn-primary"
                  type="button"
                  @click="getSignature(0)"
                  data-html2canvas-ignore="true"
                >
                  <i class="fas fa-plus"></i>
                  &nbsp;
                  <i class="fas fa-signature"></i>
                </button>
                <button
                  v-if="job.jobSignatureRequired[0]"
                  class="btn btn-danger"
                  type="button"
                  @click="resetSignature(0)"
                  data-html2canvas-ignore="true"
                >
                  <i class="far fa-trash-alt"></i>
                  &nbsp;
                  <i class="fas fa-signature"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="col-3 float-end">
            <div class="row">Date</div>
            <div class="row">{{ job.jobSignatureDate[0] }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- BUTTON PENTRU SAVE AS PDF & SEND TO API -->
    <div v-if="!!job.jobId && !isUploadingData" class="row mx-2 mb-5 p-0">
      <button
        type="button"
        class="col btn btn-primary d-block mx-2 border border-dark"
        @click="sendPDF2API('server')"
      >
        <i class="fas fa-file-upload"></i> Upload File To simPro
      </button>
      <button
        type="button"
        class="col btn btn-primary d-block mx-2 border border-dark"
        @click="sendPDF2API('local')"
      >
        <i class="fas fa-file-download"></i> Download File To Device
      </button>
    </div>
    <!-- Signature PAD -->
    <signature-pad
      v-if="!isUploadingData"
      :isVisible="signaturePad.isVisible"
      @cancelSignature="cancelSignature"
      @saveSignature="addSignature($event)"
    ></signature-pad>
  </section>
</template>

<script>
  import SignaturePad from '../components/UI/SignaturePad.vue';
  import html2pdf from 'html2pdf.js';

  export default {
    components: { SignaturePad },
    data() {
      return {
        fetchingError: false,
        fetchingErrorMessage: null,
        isFetchingData: false,
        isUploadingData: false,
        uploadMessageShow: false,
        uploadMessageOk: true,
        uploadMessage: null,
        job: {
          jobNumber: null, // 15468 - JOB pt teste
          jobId: null,
          jobName: null,
          jobAddress: null,
          jobDate: new Date().toISOString().substr(0, 10),
          jobLocationSite: null,
          jobDescription: null,
          jobEmployer: null,
          jobSteps: [],
          jobStepsOther: false,
          jobStepsOtherText: null,
          jobPPERequired: [],
          jobPPERequiredOther: true,
          jobPPERequiredOtherText: null,
          jobPlantRequired: [],
          jobPlantRequiredOther: true,
          jobPlantRequiredOtherText: null,
          jobSignageRequired: [],
          jobSignageRequiredOther: true,
          jobSignageRequiredOtherText: null,
          jobSignatureEmployees: [],
          jobSignatureRequired: [],
          jobSignatureDate: [],
          jobPDF: null,
        },
        signaturePad: {
          isVisible: false,
          jobSignatureIndex: null,
          signatureData: null,
        },
        steps: {},
        stepSelectedToAdd: 0,
        employees: [],
        employeeSelectedToAdd: 0,
        otherEmployeeName: null,
        positions: [],
        positionSelectedToAdd: 0,
        classes: {
          title2cols:
            'input-group-text bg-success text-light fw-bold col-md-4 col-5',
          title3cols: 'input-group-text fw-bold col-md-4 col-5',
        },
      };
    },
    methods: {
      // resetam datele formului
      async resetData() {
        this.job = null;
        this.job = {
          jobNumber: null, // 15468 - JOB pt teste
          jobId: null,
          jobName: null,
          jobAddress: null,
          jobDate: new Date().toISOString().substr(0, 10),
          jobLocationSite: null,
          jobDescription: null,
          jobEmployer: null,
          jobSteps: [],
          jobStepsOther: false,
          jobStepsOtherText: null,
          jobPPERequired: [],
          jobPPERequiredOther: true,
          jobPPERequiredOtherText: null,
          jobPlantRequired: [],
          jobPlantRequiredOther: true,
          jobPlantRequiredOtherText: null,
          jobSignageRequired: [],
          jobSignageRequiredOther: true,
          jobSignageRequiredOtherText: null,
          jobSignatureEmployees: [],
          jobSignatureRequired: [],
          jobSignatureDate: [],
          jobPDF: null,
        };
      },
      // trimiterea informatiei base64 catre API
      async sendPDF(pdf, fileName) {
        this.isUploadingData = true;

        // inchidem toate steps, risk si controls
        this.job.jobSteps.forEach((job) => {
          job.isExpanded = false;
          job.risks.forEach((risk) => {
            risk.isExpanded = false;
          });
        });

        this.job.jobPDF = pdf;
        // cream link-ul API catre care transmitem datele
        const apiURLforAttachingFile =
          this.$store.getters.apiURL +
          '/api/v1.0/companies/0/jobs/' +
          this.job.jobNumber +
          '/attachments/files/';

        const dataSent = new FormData();

        // -->
        dataSent.append('metoda', 'post');
        dataSent.append('Action', 'sendFileToAPI');
        dataSent.append(
          'Bearer',
          'Bearer ' + this.$store.getters.loginData['access_token']
        );
        dataSent.append('link', apiURLforAttachingFile);
        dataSent.append('Filename', fileName);
        dataSent.append('Public', true);
        dataSent.append('Email', true);
        dataSent.append('Base64Data', pdf);
        const response = await this.axios({
          method: 'post',
          url: '/api.php',
          data: dataSent,
        });
        // ---<
        console.log('response', response);
        const responseData = JSON.parse(response.data);
        console.log('responseData', responseData);
        const responseStatus = response.status;
        console.log('responseStatus', responseStatus);

        this.isUploadingData = false;
        this.uploadMessageShow = true;
        if (responseStatus === 200 && !responseData.errors) {
          this.uploadMessageOk = true;
          this.uploadMessage =
            'File <b>' +
            fileName +
            '</b> attached successfully to job number <b>' +
            this.job.jobNumber +
            '</b>';
          this.resetData();
        } else {
          this.uploadMessageOk = false;
          const responsaDataErrors = responseData.errors;
          // console.log('error', responsaDataErrors[0].message);
          this.uploadMessage = responsaDataErrors[0].message;
        }
      },
      // trimitem datele PDF-ului catre API pentru upload
      async sendPDF2API(locatie) {
        console.clear();
        // console.log('locatie: ' + locatie);
        this.fetchingError = false;
        const alertMsgDiv = this.$refs['alertMessageDiv'];
        const topAlertMsgDiv = alertMsgDiv.offsetTop - 65;
        // daca nu avem steps, hazards & controls adaugat
        if (this.job.jobSteps.length <= 0) {
          this.fetchingError = true;
          this.fetchingErrorMessage =
            'At leas 1 step is required for TA document to be created!';
          window.scrollTo(0, topAlertMsgDiv);
          return;
        }
        // daca documentul nu este semnat de cel care il creaza
        if (!this.job.jobSignatureRequired[0]) {
          this.fetchingError = true;
          this.fetchingErrorMessage =
            'Document must be signed by ' +
            this.job.jobSignatureEmployees[0].Name +
            '!';
          window.scrollTo(0, topAlertMsgDiv);
          return;
        }
        // daca nu avem acelasi numar de semnaturi ca si numarul de attendees
        if (
          this.job.jobSignatureRequired.length <= 0 ||
          this.job.jobSignatureEmployees.length !=
            this.job.jobSignatureRequired.length
        ) {
          this.fetchingError = true;
          this.fetchingErrorMessage =
            'All attendees must sign the document before uploading!';
          window.scrollTo(0, topAlertMsgDiv);
          return;
        }
        // daca avem semnaturi goale
        for (
          let index = 0;
          index < this.job.jobSignatureEmployees.length;
          index++
        ) {
          const element = this.job.jobSignatureRequired[index];
          if (element === null) {
            this.fetchingError = true;
            this.fetchingErrorMessage =
              'All attendees must sign the document before uploading!';
            window.scrollTo(0, topAlertMsgDiv);
            return;
          }
        }

        // PDF-ul
        // const pdfBody = document.getElementById("forPDF-content");
        const pdfBody = this.$refs.pdfBodyHTML;

        // cream numele fisierului
        var today = new Date();
        var yyyy = today.getFullYear();
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var dd = String(today.getDate()).padStart(2, '0');
        var HH = String(today.getHours()).padStart(2, '0');
        var min = String(today.getMinutes()).padStart(2, '0');
        var ss = String(today.getSeconds()).padStart(2, '0');
        var fileName = 'TA' + yyyy + mm + dd + HH + min + ss + '.pdf';

        // optiunile definite pentru salvarea documentului
        var opt = {
          margin: [20, 5, 12.5, 5], // top, left, bottom, right - adauga cate 5 la fiecare daca e nevoie
          filename: fileName,
          image: {
            type: 'jpeg', // jpg, jpeg, bmp, png
            quality: 1.0,
          },
          html2canvas: {
            scale: 1.5,
            dpi: 192,
            letterRendering: true,
            windowWidth: '280mm',
            windowHeight: '260mm',
            allowTaint: true,
          },
          jsPDF: {
            unit: 'mm',
            // format: "a4",
            format: [260, 280],
            orientation: 'landscape', // landscape sau portrait
            compress: true,
          },
          pagebreak: {
            mode: 'css',
            // mode: ["avoid-all", "css", "legacy"],
            before: '.break-before',
            after: '.break-after',
            avoid: '.break-avoid',
          },
        };

        // deschidem toate steps, risks si controls
        this.job.jobSteps.forEach((job) => {
          job.isExpanded = true;
          job.risks.forEach((risk) => {
            risk.isExpanded = true;
          });
        });

        // salvarea documentului in sine
        var docPDF = html2pdf()
          .from(pdfBody)
          .set(opt)
          .outputPdf()
          .get('pdf')
          .then((pdf) => {
            var totalPages = pdf.internal.getNumberOfPages();
            for (var i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              pdf.setFontSize(20);
              pdf.setTextColor(0);

              // facem HEADAER - LOGO
              pdf.addImage(require('../assets/logo.png'), 'PNG', 20, 3.5, 20, 10);

              // facem HEADER - TEXT
              pdf.text(45, 10.5, 'TASK ANALYSIS');

              // facem FOOTER
              pdf.setFontSize(10);
              pdf.text(
                pdf.internal.pageSize.getWidth() * 0.88,
                pdf.internal.pageSize.getHeight() - 3.5,
                'PAGE ' + i + '/' + totalPages
              );
            }
          });

        if (locatie == 'local') {
          await docPDF.outputPdf().save(); // il alaturam comenzii de mai sus daca vrem sa fie si downloadat
        } else {
          await docPDF.outputPdf().then((pdf) => {
            // trimitem informatia pentru a fi transmisa insimPro
            this.sendPDF(btoa(pdf), fileName);
            // console.log(fileName, pdf);
          });
          // .save(); // il alaturam comenzii de mai sus daca vrem sa fie si downloadat
        }
      },
      // resetam semnatura
      resetSignature(index) {
        this.job.jobSignatureRequired[index] = null;
        this.job.jobSignatureDate[index] = null;
      },
      // adaugam semnatura
      addSignature(data) {
        // console.log("data received: ", data);
        const index = this.signaturePad.jobSignatureIndex;
        if (!data.isEmpty) {
          this.job.jobSignatureRequired[index] = data.data;
          // this.job.jobSignatureDate[index] = new Date().toISOString().substr(0, 10);
          var currentdate = new Date();
          var datetime =
            String(currentdate.getDate()).padStart(2, '0') +
            '/' +
            String(currentdate.getMonth() + 1).padStart(2, '0') +
            '/' +
            currentdate.getFullYear() +
            ' @ ' +
            String(currentdate.getHours()).padStart(2, '0') +
            ':' +
            String(currentdate.getMinutes()).padStart(2, '0') +
            ':' +
            String(currentdate.getSeconds()).padStart(2, '0');
          this.job.jobSignatureDate[index] = datetime;
        }
        this.cancelSignature();
      },
      // anulam semnarea
      cancelSignature() {
        this.signaturePad.isVisible = false;
        this.signaturePad.jobSignatureIndex = null;
      },
      // afisam signaturePad si setam isVisible si index pt care se face
      getSignature(index) {
        this.signaturePad.isVisible = true;
        this.signaturePad.jobSignatureIndex = index;
      },
      // selectam pozitia aferenta employee selectat
      async setPositionForEmployee(employeeName) {
        // daca se selecteaza un angajat al RSB
        if (employeeName !== 'other') {
          const selectedEmployee = this.employeeSelectedToAdd;
          this.employeeSelectedToAdd = 0;
          const employeeSelected = this.employees.filter((employee) => {
            if (employee.Name.trim() === employeeName) return employee;
          });

          const dataSent = new FormData();
          dataSent.append('metoda', 'get');
          dataSent.append('Action', 'getTaskData');
          dataSent.append(
            'Bearer',
            'Bearer ' + this.$store.getters.loginData['access_token']
          );
          dataSent.append(
            'link',
            this.$store.getters.apiURL +
              '/api/v1.0/companies/0/employees/' +
              employeeSelected[0].ID +
              ''
          );

          const response = await this.axios({
            method: 'post',
            url: '/api.php',
            data: dataSent,
          });
          const responseData = JSON.parse(response.data);
          const employeePosition = responseData.Position.trim();

          var positionFound = false;
          for (let i = 0; i < this.positions.length; i++) {
            const element = this.positions[i];
            if (element.Position.trim() == employeePosition) {
              positionFound = true;
              break;
            }
          }
          if (!positionFound) {
            this.positions.push({ Position: employeePosition });
          }
          this.employeeSelectedToAdd = selectedEmployee;
          this.positionSelectedToAdd = employeePosition;
        } else {
          // daca se adauga o persoana din afara RSB
          this.positionSelectedToAdd = 'Not RSB employee';
        }
      },
      // adaugam un agnajat nou pentru semnatura
      addPosition(employeeSelectedToAdd, positionSelectedToAdd) {
        if (employeeSelectedToAdd === 'other') {
          employeeSelectedToAdd = this.otherEmployeeName;
        }
        this.job.jobSignatureEmployees.push({
          Name: employeeSelectedToAdd,
          Type: positionSelectedToAdd,
        });
        this.employeeSelectedToAdd = this.positionSelectedToAdd = 0;
        this.otherEmployeeName = null;
      },
      // eliminam employee din lista de attendees
      removeEmployee(index) {
        this.job.jobSignatureEmployees.splice(index, 1);
        this.job.jobSignatureRequired.splice(index, 1);
        this.job.jobSignatureDate.splice(index, 1);
      },
      // aducem imaginea corespunzatoarea fiecarui PPE
      getImagePPE(img) {
        const image = require('@/assets/pictograme/' + img);
        return image;
      },
      // adaugam un step la lista de step-uri
      addStep(step) {
        this.steps.forEach((element) => {
          if (element.name === step) {
            this.job.jobSteps.push(element);
          }
        });
        this.stepSelectedToAdd = step = 0;
      },
      // setam datele primite pt job numberul introdus
      setJobDetails(responseData, siteAddress) {
        this.job.jobId = responseData.ID;
        this.job.jobName = responseData.Name;
        this.job.jobAddress = siteAddress;
        this.job.jobLocationSite = responseData.Site.Name;
        this.job.jobDescription = responseData.Name;
        this.job.jobEmployer = responseData.Customer.CompanyName;

        this.job.jobSteps = [];
        this.job.jobPPERequiredOtherText = null;
        this.job.jobSignageRequired = [];
        this.job.jobSignageDate = [];
      },
      // aducem datele de la TaskDetails & listele cu date
      async getTaskDetails() {
        console.clear();

        const jobNr = this.job.jobNumber;
        await this.resetData();

        this.uploadMessageShow = false;
        this.fetchingError = false;
        this.isFetchingData = true;
        this.job.jobId =
          this.job.jobName =
          this.job.jobAddress =
          this.job.jobLocationSite =
          this.job.jobDescription =
          this.job.jobEmployer =
          this.job.jobPDF =
            null;
        this.job.jobNumber = jobNr;
        // create apiURL pentru JOB ID cautat
        const apiURL =
          this.$store.getters.apiURL +
          '/api/v1.0/companies/0/jobs/' +
          this.job.jobNumber;
        // console.log('apiURL: ', apiURL);
        // create dataSent object
        const dataSent = new FormData();

        // prima data refacem logarea
        await this.$store.dispatch('tryLogin');
        dataSent.append('Action', 'getTaskData');
        dataSent.append(
          'Bearer',
          'Bearer ' + this.$store.getters.loginData['access_token']
        );
        dataSent.append('link', apiURL);
        dataSent.append('metoda', 'get');
        const phpResponse = await this.axios({
          method: 'post',
          url: '/api.php',
          data: dataSent,
        });
        // console.log('phpResponse', phpResponse);

        // statusul trimis inapoi: 200 = ok; 404 = Job not found
        const status = phpResponse.status;
        const responseData = JSON.parse(phpResponse.data);
        // const statusText = phpResponse.statusText;
        // console.log(statusText, status, responseData);

        if (status === 200 && !responseData.errors) {
          // aducem datele pentru adresa clientului
          const siteId = responseData.Site.ID;
          const siteDataSent = new FormData();
          siteDataSent.append('Action', 'getTaskData');
          siteDataSent.append(
            'Bearer',
            'Bearer ' + this.$store.getters.loginData['access_token']
          );
          siteDataSent.append(
            'link',
            this.$store.getters.apiURL + '/api/v1.0/companies/0/sites/' + siteId
          );
          siteDataSent.append('metoda', 'get');
          const siteData = await this.axios({
            method: 'post',
            url: '/api.php',
            data: siteDataSent,
          });
          // console.log('siteData', JSON.parse(siteData.data));
          const siteDataAddress = JSON.parse(siteData.data).Address;
          var siteAddress = '' + siteDataAddress.Address;
          siteAddress += ', ' + siteDataAddress.City;
          siteAddress += ', ' + siteDataAddress.PostalCode;

          // console.log(responseData);
          await this.setJobDetails(responseData, siteAddress);

          // modificam data pentru job cu cea curenta
          const currentdate = new Date();
          const datetime =
            String(currentdate.getDate()).padStart(2, '0') +
            '/' +
            String(currentdate.getMonth() + 1).padStart(2, '0') +
            '/' +
            currentdate.getFullYear();
          this.job.jobDate = datetime;

          // aducem matricea de steps/risks/controls
          // const jsonSteps = await this.axios({
          //   method: 'get',
          //   url: '/SWMS-Risks-and-controls.json',
          // });
          const jsonStepsDataSent = new FormData();
          jsonStepsDataSent.append('Action', 'getJsonData');
          jsonStepsDataSent.append('link', 'SWMS-Risks-and-controls.json');
          const jsonSteps = await this.axios({
            method: 'post',
            url: '/api.php',
            data: jsonStepsDataSent,
          });
          // console.log('jsonSteps', jsonSteps);
          this.steps = jsonSteps.data.steps;
          // console.log('this.steps', this.steps);
          // adaugam atribut isExpanded la fiecare step si la fiecare risk aferent unui step
          this.steps.forEach((step) => {
            step['isExpanded'] = false;
            step.risks.forEach((risk) => {
              risk['isExpanded'] = false;
            });
          });

          // aducem lista de PPE
          const jsonPPE = require('../assets/jsons/ppe.json');
          this.job.jobPPERequired = jsonPPE.PPE;
          // console.log('this.job.jobPPERequired', this.job.jobPPERequired);

          // aducem lista de PLANT Required
          const jsonPLANT = require('../assets/jsons/plant.json');
          this.job.jobPlantRequired = jsonPLANT.PLANT;
          // console.log('this.job.jobPlantRequired', this.job.jobPlantRequired);

          // aducem lista de SIGNAGE Required
          const jsonSIGNAGE = require('../assets/jsons/signage.json');
          this.job.jobSignageRequired = jsonSIGNAGE.SIGNAGE;
          // console.log('this.job.jobSignageRequired', this.job.jobSignageRequired);

          // aducem lista cu employees aferenta rsb-electrical
          const apiURLforEmployees =
            this.$store.getters.apiURL +
            '/api/v1.0/companies/0/employees/?search=all&pageSize=250&orderby=Name';
          const employeesListDataSent = new FormData();
          employeesListDataSent.append('Action', 'getTaskData');
          employeesListDataSent.append(
            'Bearer',
            'Bearer ' + this.$store.getters.loginData['access_token']
          );
          employeesListDataSent.append('link', apiURLforEmployees);
          employeesListDataSent.append('metoda', 'get');
          const employeesList = await this.axios({
            method: 'post',
            url: '/api.php',
            data: employeesListDataSent,
          });
          this.employees = JSON.parse(employeesList.data);
          // console.log('employeesList', employeesList);
          this.employeeSelectedToAdd = '0';

          // aducem lista de pozitii
          const jsonPosition = require('../assets/jsons/positions.json');
          this.positions = jsonPosition.positions;

          // luam utilizatorul curent
          const currentUserDataSent = new FormData();
          currentUserDataSent.append('metoda', 'get');
          currentUserDataSent.append('Action', 'getTaskData');
          currentUserDataSent.append(
            'Bearer',
            'Bearer ' + this.$store.getters.loginData['access_token']
          );
          currentUserDataSent.append(
            'link',
            this.$store.getters.apiURL + '/api/v1.0/currentUser/'
          );
          const currentUser = await this.axios({
            method: 'post',
            url: '/api.php',
            data: currentUserDataSent,
          });
          // console.log('currentUser', currentUser);
          const userData = JSON.parse(currentUser.data);
          // console.log('userData', userData);
          this.job.jobSignatureEmployees.push({
            Name: userData.Name,
            Type: userData.Type,
          });
        } else {
          const phpResponseError = responseData.errors;
          // console.log('error', phpResponseError[0].message);
          this.fetchingErrorMessage = phpResponseError[0].message;
          this.fetchingError = true;
        }

        this.isFetchingData = false;

        // TODO: pentru a modifica json-ul, se transmite noul json catre un php in interiorul assests care pur si simplu modifica fisierul json
        // const jsonAxios = await this.axios({
        //   method: "get",
        //   url: "../assets/SWMS-Risks-and-controls.json", // citim tokenURL
        // });
        // console.log("jsonAxios: ", jsonAxios);
      },
    },
    computed: {
      // luam datele de logare
      loginData() {
        return this.$store.getters.loginData;
      },
      localStorage() {
        return (
          localStorage.getItem('userName') +
          ', ' +
          localStorage.getItem('rememberMe')
        );
      },
      // calculam steps disponibili in lista
      availableSteps() {
        if (this.steps.length <= 0) return;
        const newSteps = this.steps.filter((step) => {
          let gasit = 0;
          for (var i = 0; i < this.job.jobSteps.length; i++)
            if (
              step.name === this.job.jobSteps[i].name &&
              this.job.jobSteps[i].active === true
            )
              gasit = 1;
          if (gasit == 0) return step;
        });
        return newSteps;
      },
      // calculam angajatii disponibili in lista
      availableEmployees() {
        const newEmployees = this.employees.filter((employee) => {
          let gasit = 0;
          for (var i = 0; i < this.job.jobSignatureEmployees.length; i++) {
            if (
              employee.Name.trim() ===
              this.job.jobSignatureEmployees[i].Name.trim()
            )
              gasit = 1;
          }
          if (gasit === 0) return employee;
        });
        return newEmployees;
      },
      // lista de pozitii
      positionsList() {
        return this.positions;
      },
      // utilizatorul logat care este responsabil de document
      createdBy() {
        var user = this.job.jobSignatureEmployees[0];
        return user;
      },
      // returnam ceilalti utilizatori, altii decat cel logat
      attendeesList() {
        var attendees = [];
        for (let i = 1; i < this.job.jobSignatureEmployees.length; i++) {
          const element = this.job.jobSignatureEmployees[i];
          attendees.push(element);
        }
        return attendees;
      },
    },
  };
</script>

<style>
</style>